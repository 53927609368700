import { Table, Button, Spin } from "antd";
import moment from "moment";
import { useAdmin } from "../../hooks/useAdmin";
import ExportVisitsForm from "./ExportVisitsForm";
import EmailSettings from "./EmailSettings";
const DashboardContent = () => {
	const {
		visits,
		visitors,
		selectedView,
		dataLoading,
		onExportVisitorsFinish,
	} = useAdmin();
	if (dataLoading) {
		return <Spin size="large" />;
	}
	if (visits.length < 1) {
		return (
			<>
				<p className="text-white">No entries have been entered yet.</p>
			</>
		);
	}
	const visitsColumns = [
		{
			title: "Visit Date",
			dataIndex: "createdAt",
			key: "createdAt",
			render: (date) => <>{moment(date).format("MM-DD-YYYY")}</>,
			responsive: ["sm"],
		},
		{
			title: "Onecard ID",
			dataIndex: "visitor",
			key: "visitor",
			render: (object) => (
				<div className="hover:underline hover:cursor-pointer" id="object._id">
					{object.onecard}
				</div>
			),
		},
		{
			title: "Campus",
			dataIndex: "campus",
			key: "campus",
		},
	];

	const visitorsColumns = [
		{
			title: "Name",
			children: [
				{
					title: "First",
					dataIndex: "firstname",
					key: "firstname",
				},
				{
					title: "Last",
					dataIndex: "lastname",
					key: "lastname",
				},
			],
		},
		{
			title: "Onecard",
			dataIndex: "onecard",
			key: "onecard",
		},
		{
			title: "First Visit",
			dataIndex: "createdAt",
			key: "createdAt",
			render: (date) => <>{moment(date).format("MM-DD-YYYY")}</>,
		},
		{
			title: "Demographics",
			children: [
				{
					title: "City",
					dataIndex: "city",
					key: "city",
				},
				{
					title: "Zipcode",
					dataIndex: "zipcode",
					key: "zipcode",
				},

				{
					title: "Children",
					dataIndex: "_children",
					key: "_children",
				},
				{
					title: "Adults",
					dataIndex: "adults",
					key: "adults",
				},
				{
					title: "Seniors",
					dataIndex: "seniors",
					key: "seniors",
				},
			],
		},
	];

	switch (selectedView) {
		case "visits":
			console.log(visits);
			return (
				<>
					<Table
						style={{
							width: 735,
						}}
						columns={visitsColumns}
						dataSource={visits}
					/>
					<ExportVisitsForm />
				</>
			);
		case "visitors":
			return (
				<>
					<Table
						style={{
							width: 735,
						}}
						columns={visitorsColumns}
						dataSource={visitors}
					/>
					<Button
						onClick={onExportVisitorsFinish}
						onKeyDown={onExportVisitorsFinish}
					>
						Export
					</Button>
				</>
			);
			case "emailSettings":
				return <EmailSettings />;
		default:
			return (
				<>
					<p>Select a view from the Sidebar</p>
				</>
			);
	}
};
export default DashboardContent;
