import { useAdmin } from "../../hooks/useAdmin";
import {
	SettingOutlined,
	TableOutlined,
	TeamOutlined,
	MailOutlined,
	LogoutOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
const items = [
	{
		key: "visits",
		label: "Visits",
		icon: <TableOutlined />,
	},
	{
		key: "visitors",
		label: "Visitors",
		icon: <TeamOutlined />,
	},
	{
		key: "userSettings",
		label: "User Settings",
		icon: <SettingOutlined />,
	},
	{
		key: "emailSettings",
		label: "Email Settings",
		icon: <MailOutlined />,
	},
	{
		key: 'logout',
		label: 'Logout',
		icon: <LogoutOutlined />
	}
];
const Sidebar = () => {
	const { setSelectedView } = useAdmin();
	return (
		<Menu
			onClick={(e) => setSelectedView(e.key)}
			style={{
				width: 200,
			}}
			defaultSelectedKeys={["visits"]}
			mode="vertical"
			items={items}
		/>
	);
};
export default Sidebar;
