import { Flex } from "antd";
import Sidebar from "../components/Dashboard/Sidebar";
import DataTable from "../components/Dashboard/DashboardContent";
import { AdminPanelProvider } from "../hooks/useAdmin";

function DashboardPage() {
	return (
		<AdminPanelProvider>
			<Flex gap="large" justify="space-around">
				<Sidebar />
				<div>
					<DataTable />

				</div>
			</Flex>
		</AdminPanelProvider>
	);
}

export default DashboardPage;
