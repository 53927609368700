import { Form, Input, Button, Flex, Checkbox, Spin } from "antd";
import { useAdmin } from "../../hooks/useAdmin";
import { useCallback } from "react";


const { TextArea } = Input;

const onFinish = (values) => {
	console.log("Success:", values);
};
const onFinishFailed = (errorInfo) => {
	console.log("Failed:", errorInfo);
};
function EmailSettings() {
	const { dataLoading, emailSettings, fetchEmailSettings } = useAdmin();
	const {
		fromEmailAddress,
		emailAuthUsername,
		emailAuthPassword,
		emailEnabled,
		emailBody,
	} = emailSettings;
	if (dataLoading) {
		return <Spin size="large" />;
	}
	return (
		<Flex>
			<Form
				labelCol={{
					span: 10,
				}}
				wrapperCol={{
					span: 18,
				}}
				style={{
					maxWidth: 1400,
				}}
				initialValues={{
					remember: true,
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item
					label={<div className="text-white">From: Email Address</div>}
					name="fromEmailAddress"
					initialValue={fromEmailAddress}
					rules={[
						{
							required: true,
							message: "Please enter a source email address!",
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label={<div className="text-white">SMTP Username</div>}
					name="smtpUsername"
					initialValue={emailAuthUsername}
					rules={[
						{
							required: true,
							message: "Please input your SMTP Username!",
						},
					]}
				>
					<Input type="text" />
				</Form.Item>

				<Form.Item
					label={<div className="text-white">SMTP Password</div>}
					name="smtpPassword"
					initialValue={emailAuthPassword}
					rules={[
						{
							required: true,
							message: "Please input your SMTP Password!",
						},
					]}
				>
					<Input type="password" />
				</Form.Item>
				<Form.Item
					name={emailBody}
					label={<div className="text-white">Email Template</div>}
					initialValue={emailBody}
					rules={[
						{ required: true, message: "Please provide an email template" },
					]}
				>
					<TextArea />
				</Form.Item>
				<Form.Item
					name="emailEnabled"
					valuePropName="checked"
					wrapperCol={{
						offset: 8,
						span: 16,
					}}
				>
					<Checkbox checked={emailEnabled} name="emailEnabled">
						<div className="text-white">Email Enabled?</div>
					</Checkbox>
				</Form.Item>

				<Form.Item
					wrapperCol={{
						offset: 8,
						span: 16,
					}}
				>
					<Button type="primary">Submit</Button>
				</Form.Item>
			</Form>
		</Flex>
	);
}

export default EmailSettings;
